import React, { useEffect, useState, useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import Navigation from '../Navigation/Navigation'

import SiteLogo from '../../images/svgs/blossom-logo.svg'

import { MEASUREMENTS } from '../../helpers/constants'

export default function Header () {

	const queryData = useStaticQuery(graphql`
		query CategoryNavQuery {
			strapi {
				navigation {
					navItemsLeft {
						id
						label
						url
						subNavItems {
							id
							label
							url
						}
					}
					navItemsRight {
						id
						label
						url
						subNavItems {
							id
							label
							url
						}
					}
				}
			}
		}
	`)

	const { navItemsLeft, navItemsRight } = queryData.strapi.navigation
	const allNav = [ ...navItemsLeft, ...navItemsRight ]
	const RESIZE_DEBOUNCE = 150
	const headerRef = useRef()

	const getWidth = () => headerRef.current.offsetWidth;

	const [ menuToggled, toggleMenu ] = useState(false)
	const [ subNavToggled, toggleSubNav ] = useState(false)
	const [ width, setWidth ] = useState( 0 )

	useEffect( () => {
		if ( headerRef ) {
			setWidth( headerRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] );

	// Temporarily disabled as its casuing issues
	// useEffect(() => {
	// 	if ( menuToggled ) {
	// 		document.body.classList.add('u-body-no-scroll');
	// 	} else {
	// 		document.body.classList.remove('u-body-no-scroll');
	// 	}
	// }, [ menuToggled ] )

	return (
		<header className="header" ref={ headerRef }>

			<div className="header__container wrapper">

				<button className="header__mobile-menu-toggle u-show-tablet" onClick={ () =>toggleMenu( !menuToggled ) }>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

				{  width > MEASUREMENTS.TABLET ? (
					<>
						<div className="header__navigation-container">
							<Navigation menuOnLeft={ true } toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ navItemsLeft } />
						</div>

						<Link to="/" className="header__title-link">
							<SiteLogo className="header__logo" />
						</Link>

						<div className="header__navigation-container">
							<Navigation menuOnLeft={ false } toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ navItemsRight } />
						</div>
					</>
				) : (
					<>
						<Link to="/" className="header__title-link">
							<SiteLogo className="header__logo" />
						</Link>
						<div className="header__navigation-container">
							<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } subNavToggled={ subNavToggled } toggleSubNav={ toggleSubNav } width={ width } data={ allNav } />
						</div>
					</>
				)}
			</div>
		</header>
	)
}
