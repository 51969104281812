import React from 'react'

import Layout from './src/components/Layout/Layout'
import CookiePolicy from './src/components/Cookie/CookiePolicy'

import './src/styles/toolkit.scss'

export const shouldUpdateScroll = () => {
	// Get the user-agent string 
	let userAgentString = navigator.userAgent;

	// Detect Safari
	let safariAgent = userAgentString.indexOf("Safari") > -1;
	// Detect Chrome
	let chromeAgent = userAgentString.indexOf("Chrome") > -1;

	// Discard Safari since it also matches Chrome 
	if ((chromeAgent) && (safariAgent)) safariAgent = false;

	if ( safariAgent ) {
		setTimeout(()=> {
			window.scrollTo(0, 0)
		} )
	}

	return true
}

export const wrapRootElement = ({ element }) => (
	<Layout>
		{ element }
		<CookiePolicy />
	</Layout>
)