import React from 'react'
import { Link } from 'gatsby'

import SubNavigation from './SubNavigation'

import { addTrailingSlash } from '../../helpers/urlHelpers'

export default function Navigation( props ) {

	const { menuOnLeft, toggleMenu, menuToggled, toggleSubNav, width, data } = props

	return (
		<div className={`navigation ${ menuOnLeft ? 'navigation--left' : '' } ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( navItem => (
							<React.Fragment key={ navItem.id }>
								{ navItem.subNavItems.length ? (
									<SubNavigation navItem={ navItem } toggleMenu={ toggleMenu } menuToggled={ menuToggled } toggleSubNav={ toggleSubNav } width={ width } />
								) : (
									<li className="navigation__item">
										<Link
											to={ addTrailingSlash( navItem.url ) }
											className={`navigation__link ${ navItem.label === "Executive" ? 'navigation__link--executive' : '' }`}
											activeClassName="active"
											partiallyActive={ true }
											onClick={() => toggleMenu( false ) }>
											{ navItem.label }
										</Link>
									</li>
								) }
							</React.Fragment>
						) ) }
					</ul>
				</div>
			</nav>
		</div>
	)
}