import React from 'react'
import { Link } from 'gatsby'

import Flowers from '../../images/flowers.png'

export default function Footer() {

	return (
		<footer className="footer" style={{ backgroundImage: `url(${Flowers})` }}>
			<div className="footer__wrapper">
				<div className="footer__content-container">
					<div className="footer__info-title">the blossom charity</div>
					<div className="footer__info">Helping you become the best version of yourself</div>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Contact Us</div>
					<ul className="footer__list">
						<li className="footer__list-item"><a className="footer__link" href="tel:01379678483">01379 678483</a></li>
						<li className="footer__list-item"><a className="footer__link" href="mailto:info@theblossomcharity.co.uk">info@theblossomcharity.co.uk</a></li>
						<li className="footer__list-item"><Link className="footer__link" to="/contact-us/">Contact Us</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Who We Are</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/charitable-purpose-of-objects/">Charitable Purpose of Objects</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/our-trustees/">Our Trustees</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/our-patron/">Our Patron</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/about-us/#team-members">Our Team</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/about-us/#wider-family">Our Wider Family</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">What We Do</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/services/">Services</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/application-form/">Application Form</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/mens-application-form/">Application Form for Men’s Programme</Link></li>
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">The Technical Stuff</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/cookie-policy/">Cookie Policy</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/terms-and-privacy/">Terms &amp; Privacy Policy</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/behind-the-scenes/">Behind the Scenes</Link></li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
