/**
 * Add a trailing slash to a URL if it doesn't already have one.
 *
 * @param {string} url - The input URL that may or may not have a trailing slash.
 * @returns {string} The URL with a trailing slash added if necessary.
*/
export function addTrailingSlash( url ) {

	// Check if the URL ends with a trailing slash
	if (!url.endsWith('/')) {
		// If not, append a trailing slash
		return url + '/';
	}

	// If it already ends with a trailing slash, return the URL as it is
	return url;
}